export const iframeTOS = `<html xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40">

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<link rel=File-List href="termsofservice_files/filelist.xml">
<!--[if gte mso 9]><xml>
 <o:DocumentProperties>
  <o:Author>Mike Pollachek</o:Author>
  <o:LastAuthor>Mike Pollachek</o:LastAuthor>
  <o:Revision>2</o:Revision>
  <o:TotalTime>0</o:TotalTime>
  <o:Created>2023-03-20T20:57:00Z</o:Created>
  <o:LastSaved>2023-03-20T20:57:00Z</o:LastSaved>
  <o:Pages>1</o:Pages>
  <o:Words>2465</o:Words>
  <o:Characters>14057</o:Characters>
  <o:Lines>117</o:Lines>
  <o:Paragraphs>32</o:Paragraphs>
  <o:CharactersWithSpaces>16490</o:CharactersWithSpaces>
  <o:Version>16.00</o:Version>
 </o:DocumentProperties>
 <o:OfficeDocumentSettings>
  <o:AllowPNG/>
 </o:OfficeDocumentSettings>
</xml><![endif]-->
<link rel=themeData href="termsofservice_files/themedata.thmx">
<link rel=colorSchemeMapping href="termsofservice_files/colorschememapping.xml">
<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:SpellingState>Clean</w:SpellingState>
  <w:GrammarState>Clean</w:GrammarState>
  <w:TrackMoves>false</w:TrackMoves>
  <w:TrackFormatting/>
  <w:PunctuationKerning/>
  <w:ValidateAgainstSchemas/>
  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
  <w:DoNotPromoteQF/>
  <w:LidThemeOther>EN-US</w:LidThemeOther>
  <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
  <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
  <w:Compatibility>
   <w:BreakWrappedTables/>
   <w:SnapToGridInCell/>
   <w:WrapTextWithPunct/>
   <w:UseAsianBreakRules/>
   <w:DontGrowAutofit/>
   <w:SplitPgBreakAndParaMark/>
   <w:EnableOpenTypeKerning/>
   <w:DontFlipMirrorIndents/>
   <w:OverrideTableStyleHps/>
  </w:Compatibility>
  <m:mathPr>
   <m:mathFont m:val="Cambria Math"/>
   <m:brkBin m:val="before"/>
   <m:brkBinSub m:val="&#45;-"/>
   <m:smallFrac m:val="off"/>
   <m:dispDef/>
   <m:lMargin m:val="0"/>
   <m:rMargin m:val="0"/>
   <m:defJc m:val="centerGroup"/>
   <m:wrapIndent m:val="1440"/>
   <m:intLim m:val="subSup"/>
   <m:naryLim m:val="undOvr"/>
  </m:mathPr></w:WordDocument>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
  DefSemiHidden="false" DefQFormat="false" DefPriority="99"
  LatentStyleCount="376">
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
  <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 9"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 1"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 2"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 3"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 4"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 5"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 6"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 7"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 8"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="header"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footer"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index heading"/>
  <w:LsdException Locked="false" Priority="35" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="caption"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of figures"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope return"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="line number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="page number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of authorities"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="macro"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="toa heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 5"/>
  <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Closing"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Signature"/>
  <w:LsdException Locked="false" Priority="1" SemiHidden="true"
   UnhideWhenUsed="true" Name="Default Paragraph Font"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Message Header"/>
  <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Salutation"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Date"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Note Heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Block Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="FollowedHyperlink"/>
  <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
  <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Document Map"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Plain Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="E-mail Signature"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Top of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Bottom of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal (Web)"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Acronym"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Cite"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Code"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Definition"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Keyboard"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Preformatted"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Sample"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Typewriter"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Variable"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Table"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation subject"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="No List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Contemporary"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Elegant"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Professional"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Balloon Text"/>
  <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Theme"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
  <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
  <w:LsdException Locked="false" Priority="34" QFormat="true"
   Name="List Paragraph"/>
  <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
  <w:LsdException Locked="false" Priority="30" QFormat="true"
   Name="Intense Quote"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="19" QFormat="true"
   Name="Subtle Emphasis"/>
  <w:LsdException Locked="false" Priority="21" QFormat="true"
   Name="Intense Emphasis"/>
  <w:LsdException Locked="false" Priority="31" QFormat="true"
   Name="Subtle Reference"/>
  <w:LsdException Locked="false" Priority="32" QFormat="true"
   Name="Intense Reference"/>
  <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
  <w:LsdException Locked="false" Priority="37" SemiHidden="true"
   UnhideWhenUsed="true" Name="Bibliography"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
  <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
  <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
  <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
  <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
  <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
  <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
  <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hashtag"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Unresolved Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link"/>
 </w:LatentStyles>
</xml><![endif]-->
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:3 0 0 0 1 0;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-469750017 -1073732485 9 0 511 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:"";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;
	mso-ligatures:standardcontextual;}
h1
	{mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Heading 1 Char";
	mso-margin-top-alt:auto;
	margin-right:0in;
	mso-margin-bottom-alt:auto;
	margin-left:0in;
	mso-pagination:widow-orphan;
	mso-outline-level:1;
	font-size:24.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	font-weight:bold;}
h2
	{mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Heading 2 Char";
	mso-margin-top-alt:auto;
	margin-right:0in;
	mso-margin-bottom-alt:auto;
	margin-left:0in;
	mso-pagination:widow-orphan;
	mso-outline-level:2;
	font-size:18.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	font-weight:bold;}
p
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-margin-top-alt:auto;
	margin-right:0in;
	mso-margin-bottom-alt:auto;
	margin-left:0in;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 1";
	mso-ansi-font-size:24.0pt;
	mso-bidi-font-size:24.0pt;
	font-family:"Times New Roman",serif;
	mso-ascii-font-family:"Times New Roman";
	mso-fareast-font-family:"Times New Roman";
	mso-hansi-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	mso-font-kerning:18.0pt;
	mso-ligatures:none;
	font-weight:bold;}
span.Heading2Char
	{mso-style-name:"Heading 2 Char";
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 2";
	mso-ansi-font-size:18.0pt;
	mso-bidi-font-size:18.0pt;
	font-family:"Times New Roman",serif;
	mso-ascii-font-family:"Times New Roman";
	mso-fareast-font-family:"Times New Roman";
	mso-hansi-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	mso-font-kerning:0pt;
	mso-ligatures:none;
	font-weight:bold;}
span.SpellE
	{mso-style-name:"";
	mso-spl-e:yes;}
span.GramE
	{mso-style-name:"";
	mso-gram-e:yes;}
.MsoChpDefault
	{mso-style-type:export-only;
	mso-default-props:yes;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;
	mso-ligatures:standardcontextual;}
.MsoPapDefault
	{mso-style-type:export-only;
	margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;
	mso-header-margin:.5in;
	mso-footer-margin:.5in;
	mso-paper-source:0;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 @list l0
	{mso-list-id:865607109;
	mso-list-template-ids:1710546072;}
@list l1
	{mso-list-id:1402679774;
	mso-list-template-ids:-1296813248;}
ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
	{mso-style-name:"Table Normal";
	mso-tstyle-rowband-size:0;
	mso-tstyle-colband-size:0;
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-parent:"";
	mso-padding-alt:0in 5.4pt 0in 5.4pt;
	mso-para-margin-top:0in;
	mso-para-margin-right:0in;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:0in;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;
	mso-ligatures:standardcontextual;}
</style>
<![endif]--><!--[if gte mso 9]><xml>
 <o:shapedefaults v:ext="edit" spidmax="1026"/>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <o:shapelayout v:ext="edit">
  <o:idmap v:ext="edit" data="1"/>
 </o:shapelayout></xml><![endif]-->
</head>

<body lang=EN-US style='tab-interval:.5in;word-wrap:break-word'>

<div class=WordSection1>

<p></b></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Last updated: 03/20/2023<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Introduction<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Welcome to <span class=SpellE>AllFarmstands</span>! By using our website
and/or using the services that are provided, you acknowledge that you have
read, understood, and agree to be bound by our Terms and Conditions. These
Terms and Conditions unconditionally extend and apply to all related
applications, internet service, or website extensions. If you are not in
agreement with all of these Terms and Conditions, you are prohibited from using
this Website, and you may discontinue use immediately. <span class=SpellE>AllFarmstands</span>
recommends that you save or print a copy of these Terms and Conditions for
future reference.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Agreement to Terms and Conditions<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>AllFarmstands</span></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'> Terms <span
class=GramE>And</span> Conditions (these &quot;Terms&quot; or these &quot;Terms
and Conditions&quot;) contained in this Agreement shall govern your use of this
Website and all its content (collectively referred to herein as this
&quot;Website&quot;). These Terms outline the rules and regulations guiding the
use of <span class=SpellE>AllFarmstands</span> located at
https://www.allfarmstands.com. All materials/information/documents/services or
all other entities (collectively referred to as content) that appear on the <span
class=SpellE>AllFarmstands</span> shall be administered subject to these Terms
and Conditions. These Terms and Conditions apply in full force and effect to
your use of this Website, and the use of this Website constitutes an express
agreement with all the terms and conditions contained herein in full. Do not
continue to use this Website if you have any objection to any of the Terms and
Conditions stated on this page.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Definitions/Terminology<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>The following definitions apply to these Terms and Conditions, Privacy
Statement, Disclaimer Notice and all Agreements: &quot;User&quot;,
&quot;Visitor,&quot; &quot;Client,&quot; &quot;Customer,&quot; &quot;You&quot;
and &quot;<span class=GramE>Your</span>&quot; refers to you, the person(s) that
use this Website. &quot;<span class=SpellE>AllFarmstands</span>&quot;,
&quot;We&quot;, &quot;Our&quot; and &quot;Us&quot;, refers to our
Website/Company. &quot;Party,&quot; &quot;Parties,&quot; or &quot;Us,&quot;
refers to both you and us. All terms refer to all considerations of <span
class=SpellE>AllFarmstands</span> necessary to undertake support to you for the
express purpose of meeting your User needs in respect of our services, under
and subject to, prevailing law of the state or country in which AllFarmstands
operates (USA). Any use of these definitions or other glossary in the singular,
plural, capitalization, and/or pronoun are interchangeable but refer to the
same.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Intellectual Property Rights<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Other than the content you own and opted to include on this Website,
under these Terms, <span class=SpellE>AllFarmstands</span> and/or its licensors
own and reserve all intellectual property rights of this Website. You are
granted a limited license, subject to the restrictions entailed in these Terms
and Conditions, for purposes of viewing this Website's content.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Services<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>The content of this Website is not intended for use or distribution to
any person or entity in any jurisdiction, geographical location, or
country/state where such use or distribution will be contrary to the laws and
regulations or subject <span class=SpellE>AllFarmstands</span> to any form of
registration, claims, demands, costs, liabilities, damages, or expenses.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>The Website is intended for users who are at least 18 years of age. If
you are under the age of 18, you cannot use or register to use this Website or
its services without parental permission or consent. By agreeing to these Terms
and Conditions, you have the necessary legal capacity to comply and be bound by
these Terms and Conditions.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Acceptable Use<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>You may use this Website as permitted by these Terms and Conditions and
may not use this Website for any purpose other than for which <span
class=SpellE>AllFarmstands</span> makes the Website and its services available.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Cookies<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>AllFarmstands</span></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'> employs the use of
cookies. By accessing Our Website, <span class=GramE>You</span> agree to use
cookies in Agreement with our Cookie Policy.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Our interactive Website's optimal functionality uses cookies to retrieve
the User's information for each visit. Some of our affiliate partners may also
use cookies.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>License<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Unless otherwise stated, <span class=SpellE>AllFarmstands</span> and/or
its licensors own the intellectual property rights for all content on <span
class=SpellE>AllFarmstands</span>. All intellectual property rights are
reserved. You may access any Website content from <span class=SpellE>AllFarmstands</span>
for your personal use subject to restrictions set in these terms and
conditions.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>AllFarmstands</span></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'> hereby restricts
you from all of the following:<o:p></o:p></span></p>

<ol start=1 type=1>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l1 level1 lfo1;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Republishing
     any <span class=SpellE>AllFarmstands</span> content in any media;<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l1 level1 lfo1;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Reproducing,
     duplicating, or copying any <span class=SpellE>AllFarmstands</span> <span
     class=GramE>content ;</span><o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l1 level1 lfo1;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Selling,
     renting, sublicensing, and/or otherwise commercializing any <span
     class=SpellE>AllFarmstands</span> content;<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l1 level1 lfo1;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Publicly
     performing and/or displaying any <span class=SpellE>AllFarmstands</span>
     content;<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l1 level1 lfo1;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Using this
     Website in a manner that is, or maybe, damaging and/or impacts user access
     to this Website;<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l1 level1 lfo1;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Using this
     Website contrary to the relevant rules, laws, and regulations of your
     country of residence, or in a manner that causes, or may cause, harm to
     the Website, or any person or business entity;<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l1 level1 lfo1;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Conducting data
     mining or any other similar activity concerning this Website, or while
     using this Website; and<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l1 level1 lfo1;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Using this
     Website to engage in any form of business advertising or marketing.<o:p></o:p></span></li>
</ol>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Specific areas of this Website may be restricted from user access, and <span
class=SpellE>AllFarmstands</span> may further extend such restriction to the
entire <span class=GramE>Website</span>, at any time, and in its sole
discretion. Any user identification, security key, or password you may have on
this Website are confidential, and you are responsible for maintaining such
information confidentiality.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Linking and Hyperlinking Rights<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>We reserve the right to file requests that you remove all links or any
particular link created by you that redirect to our Website, and you approve to
immediately remove such links to our Website upon request. We may amend the
terms and conditions of these linking rights at any time. By continuously
linking to our <span class=GramE>Website</span>, you agree to be bound to and
follow the terms of this linking policy.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Feel free to contact us if you find any link on our <span class=GramE>Website</span>
that is offensive, and we may consider requests to remove such links. Still, we
are not obligated to do so or respond to you directly or immediately.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Hyperlinking to our Content</span></b><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Organizations such as search engines, government agencies, news
organizations, and online directories may link to our <span class=GramE>Website</span>
without prior written approval. We may review other link requests from popular
consumer and/or information specialists, charity organizations, internet
portals, educational institutions, trade associations, and dot.com community
sites. Any interested organization must inform and contact us for further
information regarding our linking policy. However, such linking does not imply
endorsement, sponsorship, partnership, or approval by us of any kind.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>No use of our logo or other design intellectual property will be allowed
for linking, except a trademark license agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Link to third-party content</span></b><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>This Website may contain links to websites or applications operated by
third parties. Please know that we do not control any such third-party websites
or applications or the <span class=GramE>third party</span> operator. <span
class=SpellE>AllFarmstands</span> is not responsible for and does not endorse
any third-party websites or applications or their availability or content.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>AllFarmstands</span></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'> accepts no
responsibility for adverts contained within the Website. You agree that you do
so at your own risk when you purchase any goods and/or services from any such
third party. The advertiser, and not us, remains responsible for such goods
and/or services, and if you have any questions or complaints about them, you
should contact the advertiser<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>User Content<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>In these Website Standard Terms and Conditions, &quot;User Content&quot;
shall mean any audio, video, text, images, or other material or content you
choose to display on this Website. Concerning user content, by displaying it,
you grant <span class=SpellE>AllFarmstands</span> a non-exclusive, worldwide,
irrevocable, royalty-free, sub-licensable license to use, reproduce, adapt,
publish, translate and distribute it on any media.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>User Content must be your own and must not be infringing on any third
party's rights. <span class=SpellE>AllFarmstands</span> reserves the right to
remove any of your content from this Website at any time, without notice.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>AllFarmstands</span></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'> is permitted to
monitor your activities on the Website and remove any user content considered
inappropriate, offensive, contrary to applicable laws and regulations, or
causes a breach of these Terms and Conditions.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>You warrant and represent that:<o:p></o:p></span></p>

<ol start=1 type=1>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo2;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>You are
     entitled to upload/input/publicize content on our <span class=GramE>Website</span>
     and have the necessary legal capacity, license or consent to do so;<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo2;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Your content
     does not infringe any intellectual property right, including without
     limitation to copyright, patent, or trademark of any third party;<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo2;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Your content is
     true, accurate, current, complete, and relate to you and not a third
     party;<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo2;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Your content
     does not contain any libelous, defamatory, offensive, immoral, or
     otherwise illegal material which is an invasion of privacy; and<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
     line-height:normal;mso-list:l0 level1 lfo2;tab-stops:list .5in'><span
     style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
     "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>The content
     will not be used to solicit or promote business or custom or present
     commercial activities or unlawful activity.<o:p></o:p></span></li>
</ol>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>You now grant <span class=SpellE>AllFarmstands</span> a non-exclusive
license to use, reproduce, edit and authorize others approved by us to use,
reproduce and edit any of your content in any form, format, or media.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Privacy Policy<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>By using this Website and its services, you may provide us with certain
personal information. By using <span class=SpellE>AllFarmstands</span> or its
services, you authorize us to use your information in any country or state that
we operate in. We reserve the right to use such information to improve your
user experience and facilitate mailing and traffic, and market analytics.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>By accessing this Website, specific information about the User, such as
Internet protocol (IP) addresses, site navigation, user software, and the
surfing time, along with other similar information, will be stored on <span
class=SpellE>AllFarmstands</span> servers. Information about their identities,
such as name, address, contact details, billing information, and other
information stored on this Website, will strictly be used only for statistical
purposes and will not be published for general access. <span class=SpellE>AllFarmstands</span>,
however, assumes no responsibility for the security of this information.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Disclaimers/Warranties/Limitation of
Liabilities<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>AllFarmstands</span></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'> Website is provided
&quot;as is,&quot; with all liabilities, and <span class=SpellE>AllFarmstands</span>
makes no express or implied undertakings, representations, or warranties, of
any kind related to this Website or the content contained on this Website.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>AllFarmstands</span></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'> does not make any
endorsements, warranties, or representations about the accuracy, reliability,
expertise, or completeness of any such content. You agree that reliance on any
such content shall be at the User's risk. The <span class=SpellE>AllFarmstands</span>
periodically changes, adds, modifies, improves, or updates this Website's
consent with or without prior notice. Under no circumstance shall <span
class=SpellE>AllFarmstands</span> be liable for any loss, damage, injury,
liability, or expense incurred or suffered from the use of this Website,
including, without limitation, any fault, error, omission, commission, delay,
failure, interruption, deletion, alteration, disruption, cessation or incursion
concerning such use by us, our affiliates or any third party. Under no
circumstance shall <span class=SpellE>AllFarmstands</span> or any of its
partners and affiliates be liable for any direct, indirect, consequential,
accidental, or special damages, even if <span class=SpellE>AllFarmstands</span>
has been advised against the risk or possibility of such damages. The User
agrees that <span class=SpellE>AllFarmstands</span> will not be liable for any
conduct or <span class=SpellE>behaviour</span> of the User arising from the use
of this Website. As a result, the use of this Website and all or any of its
content is at the User's sole risk.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>In no event shall <span class=SpellE>AllFarmstands</span>, nor any of its
officers, directors, employees, and affiliates, be liable for any loss, injury,
or damage arising out of your use of this Website, whether, under contract,
tort, or otherwise, and <span class=SpellE>AllFarmstands</span>, including its
officers, directors, employees, and affiliates shall not be liable for any
indirect, consequential or special liability arising out of your use of this
Website.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Indemnification<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>As a condition for the use of this Website, the User agrees to indemnify <span
class=SpellE>AllFarmstands</span> and its affiliates to the fullest extent,
from and against all actions, claims, liabilities, losses, damages, costs,
demands, and expenses (including reasonable attorney's fees) arising out of the
User's use of this Website, including without limitation, any claim related to
the breach of any of the provisions of these Terms and Conditions. If
dissatisfied with any or all of the content on this Website or any or all of
its Terms and Conditions, the User may discontinue using this Website.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Termination<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>The provisions of these Terms and Conditions shall remain in full force
and effect while you use the <span class=SpellE>AllFarmstands</span> or its
services. Users may terminate their use by following the instructions for
terminating user accounts in your account settings or by contacting us at
support@allfarmstands.com.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>We reserve the right and sole discretion to, and without notice or
liability, deny access to and use of the Website (including blocking specific
IP addresses) to any user for any reason including but not limited to breach of
any representation, warranty, or Agreement in these Terms or any applicable law
or regulation.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>We also reserve the right, if, in our sole discretion, we determine that
your use of the Website or its services is in breach of these Terms and
Conditions or of any applicable law or regulation, to terminate your use of the
Website and its services or delete your account and any or all of your content,
without warning or prior notice. Suppose we terminate or suspend your account
for any reason set out under this section. In that case, you are prohibited
from registering and creating a new account under your name, or a false
identity, or the expression of a third party. In addition to terminating or
suspending your account, <span class=SpellE>AllFarmstands</span> reserves the
right to take appropriate legal action(s), including without limitation
pursuing civil, criminal, and injunctive redress.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>General Provisions<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Language</span></b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>All correspondence made under this Agreement shall be in English.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Governing Law &amp; Jurisdiction</span></b><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>The Terms and Conditions of this Website will be governed by and
construed under the laws of the country or state in which <span class=SpellE>AllFarmstands</span>
operates. You hereby unconditionally submit to the non-exclusive jurisdiction
of the courts located in USA for the resolution of any disputes.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Severability</span></b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Suppose any of Term or Condition is proven to be unenforceable or void
under any applicable law. In that case, such shall not render the entirety of
these Terms and Conditions unenforceable or invalid. As a result, any such
provision shall be deleted without affecting the remaining provisions herein.
The provisions of these Terms and Conditions that are unlawful, void, or unenforceable
are deemed severable from these Terms and Conditions and do not affect any
remaining provisions' validity and enforceability.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Variation of Terms</span></b><span style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-font-kerning:
0pt;mso-ligatures:none'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>AllFarmstands</span></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'> reserves the right
to revise these Terms at any time as it sees fit. By using <span class=SpellE>AllFarmstands</span>,
you are expected to review such Terms regularly to ensure you comprehend all
the Terms and Conditions regarding the use of this Website.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Assignment</span></b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span class=SpellE><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>AllFarmstands</span></span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'> reserves the right
to assign, transfer, and subcontract its rights and/or obligations under this
Agreement without any prior notification or consent required. Users shall not
be permitted to assign, transfer, or subcontract any of your rights and/or
obligations under these Terms. Furthermore, a person who is not a party to
these Terms and Conditions shall have no right to enforce any provision
contained therein.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Preservation of Immunities</span></b><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Nothing herein shall constitute a limitation upon the privileges and
immunities of <span class=SpellE>AllFarmstands</span>, which are specifically
reserved.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Waiver</span></b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Our failure to exercise any or all of these Terms and Conditions'
provisions at any point in time shall not operate as a waiver of such right or
provision.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><b><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>Entire Agreement</span></b><span style='font-size:12.0pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-font-kerning:
0pt;mso-ligatures:none'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>These Terms and Conditions, including any legal notices and disclaimers
on this Website, constitute the entire Agreement between <span class=SpellE>AllFarmstands</span>
and you concerning your use of this Website. Ultimately, this Agreement
supersedes all prior agreements and understandings concerning the same.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal;mso-outline-level:2'><b><span style='font-size:18.0pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
mso-font-kerning:0pt;mso-ligatures:none'>Contact us<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:normal'><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
none'>To resolve any complaint or clarification regarding the use of this
Website or its services or receive information concerning that, please contact
us at support [at] allfarmstands.com.<o:p></o:p></span></p>

<p class=MsoNormal><o:p>&nbsp;</o:p></p>

</div>

</body>

</html>`
