const environment = 'production'
export let backendUrl = ''
export let siteUrl = ''
export let localPath = ''
export let mobileUrl = ''

if (environment === 'development') {
  backendUrl = 'http://localhost:8080'
} else {
  backendUrl = 'https://www.allfarmstands.com:8443'
}

if (environment === 'development') {
  siteUrl = 'http://localhost:3000'
} else {
  siteUrl = 'https://www.allfarmstands.com'
}

if (environment === 'development') {
  localPath = 'http://localhost:8080'
} else {
  localPath = '/home/servers/farmstandfinder_backend'
}

if (environment === 'development') {
  mobileUrl = 'http://192.168.7.129:3000/reactnativemaps'
} else {
  mobileUrl = 'https://www.allfarmstands.com/reactnativemaps'
}
